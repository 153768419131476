import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { getTeamColor } from "../util/team-metadata";
import { TeamCheckedEntry } from "../util/ChartTeamSelection";

export interface QualiGapsMultiSeasonChartInput {
  teamName: string;
  gaps: { season: number; gap: number }[];
}

export const QualiGapsMultiSeasonChart = (props: {
  data: QualiGapsMultiSeasonChartInput[];
  checkedTeams: TeamCheckedEntry[];
}) => {
  const { data, checkedTeams } = props;

  const dataLines = data
    .filter(({ teamName }) => {
      const item = checkedTeams.find((e) => e.teamName === teamName);

      return item && item.checked;
    })
    .map(({ teamName, gaps }) => {
      return (
        <Line
          dataKey="gap"
          data={gaps}
          name={teamName}
          stroke={getTeamColor(teamName)}
          key={teamName}
        />
      );
    });

  return (
    <ResponsiveContainer width="100%" height={600}>
      <LineChart margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
        <XAxis
          dataKey="season"
          type="category"
          allowDuplicatedCategory={false}
          interval={0}
          // angle={15}
        />
        <YAxis dataKey="gap" tickFormatter={(e) => (e ? `${e} s.` : "")} />
        <Tooltip
          formatter={(e: any) => `+${e} s.`}
          labelFormatter={(e: any) => `Season ${e}`}
          itemSorter={(item) => item.value as number}
        />
        <CartesianGrid stroke="#f5f5f5" />
        {dataLines}
      </LineChart>
    </ResponsiveContainer>
  );
};
