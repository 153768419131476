import { Callbacks } from "../../../types/util";
import { getRequest } from "../../index";

const getSessionGapsUrl = (route: string) => `gaps/quali/session/${route}`;

async function getSessionQualiGaps(
  season: number,
  round: number,
  { onSuccess, onFailed, onDone }: Callbacks
) {
  getRequest(
    getSessionGapsUrl(""),
    { season, round },
    (response) => {
      try {
        if (response.data && response.data.errorMessage) {
          const { errorMessage } = response.data;

          console.error(
            `[api/gaps/quali/sessionGaps] Error while loading season quali gaps: ${errorMessage}`
          );

          if (onFailed) onFailed(errorMessage);

          return { error: errorMessage };
        }

        const averageGaps = response.data.averageGaps.gaps;
        const bestGaps = response.data.bestGaps.gaps;

        if (onSuccess) onSuccess({ averageGaps, bestGaps });
      } catch (error) {
        console.error(
          `[api/gaps/quali/sessionGaps] Error while loading season quali gaps: ${error}`
        );
        if (onFailed) onFailed(error);
      } finally {
        if (onDone) onDone();
      }
    },
    (error: any) => console.log("error on get season quali gaps", error)
  );
}

export const SessionGapsAPI = {
  getSessionQualiGaps,
};
