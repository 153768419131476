import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import ModalQualiGapTable from "../../tables/ModalQualiGapTable";

import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

import { SeasonInput } from "../../util/SeasonInput";
import { RoundInput } from "../../util/RoundInput";
import { LATEST_YEAR } from "../../util/Constants";
import { F1GapsToast } from "../../util/F1GapsToast";
import { QualiGapDataType } from "../../util/QualiGapDataEnum";
import { QualiModeRadio } from "../../util/QualiModeRadio";
import withLoading, { LoadingHOCConsumerProps } from "../../util/LoadingHOC";
import { F1GapsCircularProgress } from "../../util/F1GapsCircularProgress";

import {
  ModalQualiGaps,
  SessionRound,
  SessionRoundExtended,
} from "../../../types/Core.types";

import { SeasonGeneralAPI } from "../../../api/general/general";
import { SessionGapsAPI } from "../../../api/gaps/quali/sessionGaps.api";

import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import {
  qualiSessionsGapsLastRound,
  qualiSessionsGapsLastSeason,
  qualiSessionsGapsRounds,
  qualiSessionsGapsEntries,
  QualiSessionsReduxAPI,
} from "../../../redux/reducers/qualiSessionsGapsSlice";

interface SessionQualiGapsProps extends LoadingHOCConsumerProps {}

const SessionQualiGaps = (sessionQualiGapsProps: SessionQualiGapsProps) => {
  const { loading, loadingOn, loadingOff } = sessionQualiGapsProps;

  const lastSeasonEntry = useAppSelector(qualiSessionsGapsLastSeason);
  const lastRoundEntry = useAppSelector(qualiSessionsGapsLastRound);

  const [season, setSeason] = useState(lastSeasonEntry || LATEST_YEAR);
  const [round, setRound] = useState(lastRoundEntry || 1);
  const [mode, setMode] = useState("average");
  const [rounds, setRounds] = useState<SessionRound[]>([]);
  const [initialValue, setInitialValue] = useState(1);

  const [gaps, setGaps] = useState<ModalQualiGaps>({
    averageGaps: [],
    bestGaps: [],
  });

  const appDispatch = useAppDispatch();

  const qualiSessionEntries = useAppSelector(qualiSessionsGapsEntries);
  const qualiSessionRounds = useAppSelector(qualiSessionsGapsRounds);

  const getSessionQualiGaps = (seasonVal: number, roundVal: number) => {
    loadingOn();

    const entry = qualiSessionEntries.find(
      (e) => e.season === seasonVal && e.round === roundVal
    );

    if (!entry) {
      executeRequest(seasonVal, roundVal);
    } else {
      setGaps(entry.gaps);
      loadingOff();

      if (seasonVal === LATEST_YEAR) {
        executeRequest(seasonVal, roundVal);
      }
    }
  };

  const executeRequest = (seasonVal: number, roundVal: number) => {
    SessionGapsAPI.getSessionQualiGaps(seasonVal, roundVal, {
      onSuccess: ({ averageGaps, bestGaps }: ModalQualiGaps) => {
        setGaps({ averageGaps, bestGaps });

        appDispatch(
          QualiSessionsReduxAPI.addEntry({
            season: seasonVal,
            round: roundVal,
            gaps: { averageGaps, bestGaps },
          })
        );
      },
      onFailed: (err) => toast.error(err),
      onDone: () => loadingOff(),
    });
  };

  const loadRounds = (forSeason: number, extraCb?: any) => {
    loadingOn();

    const roundsEntry = qualiSessionRounds.find((e) => e.season === forSeason);

    if (!roundsEntry) {
      SeasonGeneralAPI.getSeasonMetadata(forSeason, {
        onSuccess: (data: SessionRoundExtended[]) => {
          const newRounds: SessionRound[] = data.map(
            ({ eventName, round }) => ({
              eventName,
              round,
            })
          );

          setRounds(newRounds);

          appDispatch(
            QualiSessionsReduxAPI.setRoundsPerSeason({
              season: forSeason,
              rounds: newRounds,
            })
          );

          if (extraCb) extraCb(newRounds);
        },
        onFailed: (err: any) => console.error(err),
        onDone: () => loadingOff(),
      });
    } else {
      setRounds(roundsEntry.rounds);

      if (extraCb) extraCb(roundsEntry.rounds);

      loadingOff();
    }
  };

  const updateSeason = (newSeason: number) => {
    setSeason(newSeason);

    appDispatch(
      QualiSessionsReduxAPI.setLastSeasonInput({ lastSeason: newSeason })
    );

    loadRounds(newSeason);
  };

  const updateRound = (newRound: number) => {
    setRound(newRound);

    appDispatch(
      QualiSessionsReduxAPI.setLastRoundInput({ lastRound: newRound })
    );

    getSessionQualiGaps(season, newRound);
  };

  useEffect(() => {
    loadRounds(season, (newRounds: SessionRound[]) => {
      const roundToSet =
        lastRoundEntry || newRounds[newRounds.length - 1].round;

      setInitialValue(roundToSet);
      setRound(roundToSet);

      getSessionQualiGaps(season, roundToSet);
    });
  }, []);

  return (
    <Box>
      <Typography>
        Use this tab to see the average gaps in quali between teams for a single
        quali session.
      </Typography>

      <Box
        sx={{
          paddingTop: "10px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SeasonInput
          onChangeCallback={updateSeason}
          disabled={loading}
          initialValue={season}
        />
        <Box sx={{ paddingLeft: "10px" }}>
          <RoundInput
            rounds={rounds}
            onChangeCallback={updateRound}
            initialValue={initialValue}
            disabled={loading}
          />
        </Box>
        {loading ? <F1GapsCircularProgress /> : null}
      </Box>

      <QualiModeRadio onChangeCallback={setMode} disabled={loading} />

      <ModalQualiGapTable
        gaps={gaps}
        mode={
          mode === "average" ? QualiGapDataType.AVERAGE : QualiGapDataType.BEST
        }
      />
      <F1GapsToast />
    </Box>
  );
};

export default withLoading(SessionQualiGaps);
