import { createSlice } from "@reduxjs/toolkit";

import { LATEST_YEAR } from "../../components/util/Constants";

import { TeamQualiGapsOverSeasonAPIResponse } from "../../types/API.types";

import { RootState } from "../store";

interface QualiTeamsOverSeasonGapsEntry {
  season: number;
  gaps: TeamQualiGapsOverSeasonAPIResponse;
}

export interface QualiTeamsOverSeasonGapsState {
  entries: QualiTeamsOverSeasonGapsEntry[];
  lastSeasonInput: number;
}

export const initialState: QualiTeamsOverSeasonGapsState = {
  entries: [],
  lastSeasonInput: LATEST_YEAR,
};

export const qualiTeamsOverSeasonGapsSlice = createSlice({
  name: "qualiTeamsOverSeason",
  initialState,
  reducers: {
    addEntry: (
      state: QualiTeamsOverSeasonGapsState,
      action: {
        payload: {
          season: number;
          gaps: TeamQualiGapsOverSeasonAPIResponse;
        };
      }
    ) => {
      const { season, gaps } = action.payload;

      state.entries.push({ season, gaps });
    },
    setLastSeasonInput: (
      state: QualiTeamsOverSeasonGapsState,
      action: { payload: { lastSeason: number } }
    ) => {
      state.lastSeasonInput = action.payload.lastSeason;
    },
  },
});

const { addEntry, setLastSeasonInput } = qualiTeamsOverSeasonGapsSlice.actions;

export const QualiTeamsOverSeasonGapsAPI = {
  addEntry,
  setLastSeasonInput,
};

export const selectQualiTeamsOverSeason = (state: RootState) =>
  state.qualiTeamsOverSeasonGaps;

export const qualiTeamsOverSeasonGapsEntries = (state: RootState) =>
  selectQualiTeamsOverSeason(state).entries;

export const qualiTeamsOverSeasonGapsLastSeasonInput = (state: RootState) =>
  selectQualiTeamsOverSeason(state).lastSeasonInput;

export default qualiTeamsOverSeasonGapsSlice.reducer;
