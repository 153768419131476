import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../store";

import { LATEST_YEAR } from "../../components/util/Constants";

import { ModalQualiGaps, SessionRound } from "../../types/Core.types";

interface QualiSessionsGapsEntry {
  season: number;
  round: number;
  gaps: ModalQualiGaps;
}

export interface QualiSessionsRoundsPerSeasonEntry {
  season: number;
  rounds: SessionRound[];
}

export interface QualiSessionsGapsState {
  entries: QualiSessionsGapsEntry[];
  roundsPerSeason: QualiSessionsRoundsPerSeasonEntry[];
  lastSeasonInput: number;
  lastRoundInput: number;
}

export const initialState: QualiSessionsGapsState = {
  entries: [],
  roundsPerSeason: [],
  lastSeasonInput: LATEST_YEAR,
  lastRoundInput: 1,
};

export const qualiSessionsGapsSlice = createSlice({
  name: "qualiSessions",
  initialState,
  reducers: {
    addEntry: (
      state: QualiSessionsGapsState,
      action: {
        payload: {
          season: number;
          round: number;
          gaps: ModalQualiGaps;
        };
      }
    ) => {
      const { season, round, gaps } = action.payload;

      state.entries.push({ season, round, gaps });
    },
    setRoundsPerSeason: (
      state: QualiSessionsGapsState,
      action: { payload: { season: number; rounds: SessionRound[] } }
    ) => {
      const { season, rounds } = action.payload;

      state.roundsPerSeason.push({ season, rounds });
    },
    setLastSeasonInput: (
      state: QualiSessionsGapsState,
      action: { payload: { lastSeason: number } }
    ) => {
      state.lastSeasonInput = action.payload.lastSeason;
    },
    setLastRoundInput: (
      state: QualiSessionsGapsState,
      action: { payload: { lastRound: number } }
    ) => {
      state.lastRoundInput = action.payload.lastRound;
    },
  },
});

const { addEntry, setRoundsPerSeason, setLastSeasonInput, setLastRoundInput } =
  qualiSessionsGapsSlice.actions;

export const QualiSessionsReduxAPI = {
  addEntry,
  setRoundsPerSeason,
  setLastSeasonInput,
  setLastRoundInput,
};

export const selectQualiSessions = (state: RootState) =>
  state.qualiSessionsGaps;

export const qualiSessionsGapsEntries = (state: RootState) =>
  selectQualiSessions(state).entries;

export const qualiSessionsGapsRounds = (state: RootState) =>
  selectQualiSessions(state).roundsPerSeason;

export const qualiSessionsGapsLastSeason = (state: RootState) =>
  selectQualiSessions(state).lastSeasonInput;
export const qualiSessionsGapsLastRound = (state: RootState) =>
  selectQualiSessions(state).lastRoundInput;

export const qualiSessionsLastInputs = (state: RootState) => [
  qualiSessionsGapsLastSeason(state),
  qualiSessionsGapsLastRound(state),
];

export default qualiSessionsGapsSlice.reducer;
