import { TextField } from "@mui/material";
import { useState } from "react";
import { LATEST_YEAR, FIRST_YEAR } from "./Constants";

interface SeasonInputProps {
  onChangeCallback: (newValue: number) => void;
  disabled?: boolean;
  initialValue?: number;
}

export const SeasonInput = (props: SeasonInputProps) => {
  const { onChangeCallback, disabled, initialValue } = props;

  const [season, setSeason] = useState(initialValue || LATEST_YEAR);

  const onChangeLocal = (event: any) => {
    const {
      target: { value },
    } = event;

    setSeason(value);

    if (LATEST_YEAR >= value && FIRST_YEAR <= value) {
      onChangeCallback(value);
    }
  };

  return (
    <TextField
      type="number"
      label="Season"
      value={season}
      onChange={onChangeLocal}
      disabled={disabled}
    />
  );
};
