import { Callbacks } from "../../../types/util";
import { getRequest } from "../../index";

const getSeasonGapsUrl = (route: string) => `gaps/quali/season/${route}`;
const getSeasonsGapsUrl = (route: string) => `gaps/quali/seasons/${route}`;

async function getSeasonQualiGaps(
  season: number,
  { onSuccess, onFailed, onDone }: Callbacks
) {
  getRequest(
    getSeasonGapsUrl(""),
    { season },
    (response) => {
      try {
        if (response.data && response.data.errorMessage) {
          const { errorMessage } = response.data;

          console.error(
            `[api/gaps/quali/seasonGaps] Error while loading season quali gaps: ${errorMessage}`
          );

          if (onFailed) onFailed(errorMessage);

          return { error: errorMessage };
        }

        const { gaps: qualiGaps } = response.data;

        if (onSuccess) onSuccess(qualiGaps);
      } catch (error) {
        console.error(
          `[api/gaps/quali/seasonGaps] Error while loading season quali gaps: ${error}`
        );
        if (onFailed) onFailed(error);
      } finally {
        if (onDone) onDone();
      }
    },
    (error: any) => console.log("error on get season quali gaps", error)
  );
}

async function getTeamGapsOverSeason(
  season: number,
  { onSuccess, onFailed, onDone }: Callbacks
) {
  getRequest(
    getSeasonGapsUrl("evolution"),
    { season },
    (response) => {
      try {
        if (response.data && response.data.errorMessage) {
          const { errorMessage } = response.data;

          console.error(
            `[api/gaps/quali/seasonGaps/getTeamGapsOverSeason] Error while loading team season quali gaps: ${errorMessage}`
          );

          if (onFailed) onFailed(errorMessage);

          return { error: errorMessage };
        }

        const { teams, teamsAverageGaps, teamsBestGaps } = response.data;

        if (onSuccess) onSuccess({ teams, teamsAverageGaps, teamsBestGaps });
      } catch (error) {
        console.error(
          `[api/gaps/quali/seasonGaps/getTeamGapsOverSeason] Error while loading team season quali gaps: ${error}`
        );
        if (onFailed) onFailed(error);
      } finally {
        if (onDone) onDone();
      }
    },
    (error: any) => console.log("error on get season quali gaps", error)
  );
}

async function getTeamGapsOverMultipleSeasons(
  seasonFrom: number,
  seasonTo: number,
  { onSuccess, onFailed, onDone }: Callbacks
) {
  getRequest(
    getSeasonsGapsUrl("evolution"),
    { seasonFrom, seasonTo },
    (response) => {
      try {
        if (response.data && response.data.errorMessage) {
          const { errorMessage } = response.data;

          console.error(
            `[api/gaps/quali/seasonGaps/getTeamGapsOverMultipleSeasons] Error while loading team season quali gaps: ${errorMessage}`
          );

          if (onFailed) onFailed(errorMessage);

          return { error: errorMessage };
        }

        const { seasonsGaps } = response.data;

        if (onSuccess) onSuccess(seasonsGaps);
      } catch (error) {
        console.error(
          `[api/gaps/quali/seasonGaps/getTeamGapsOverMultipleSeasons] Error while loading team season quali gaps: ${error}`
        );
        if (onFailed) onFailed(error);
      } finally {
        if (onDone) onDone();
      }
    },
    (error: any) => console.log("error on get season quali gaps", error)
  );
}

export const SeasonGapsAPI = {
  getSeasonQualiGaps,
  getTeamGapsOverSeason,
  getTeamGapsOverMultipleSeasons,
};

// export class BackendService {
//   private baseUrl = backendUrl;
//   private getSessionVsSeasonQualiGapDifferenceUrl = `${this.baseUrl}/gaps/quali/sessionvseason`;
//   private getTeamsInSeasonUrl = (season: string) =>
//     `${this.baseUrl}/seasons/${season}/teams`;

//   /**
//    * Retrieves the average gap before a round and the gap at the round, with their difference.
//    * @param season F1 season.
//    * @param round Round used as a difference point.
//    * @returns Array of average gaps before round, gap of round and difference of both.
//    */
//   getSessionVsSeasonQualiGapsDifference(season: string, round: string) {
//     const options = this.buildSeasonRoundOption(season, round);

//     return this.http.get(this.getSessionVsSeasonQualiGapDifferenceUrl, options);
//   }

//   getTeamsInSeason(season: string): any {
//     const options = this.buildOptions({});

//     return this.http.get(this.getTeamsInSeasonUrl(season), options);
//   }

//   /**
//    * Builds a options object to be passed in a HTTP request.
//    * @param params Parameters to be added to the options object.
//    * @returns Object containing headers and params entries.
//    */
//   buildOptions(params: any) {
//     let options = { headers: new HttpHeaders(), params };

//     return options;
//   }

//   buildSeasonRoundOption(season: string, round: string) {
//     return this.buildOptions({ season, round });
//   }
// }
