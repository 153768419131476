import { combineReducers, configureStore } from "@reduxjs/toolkit";

import {
  loadState,
  saveState as saveStateInLocalStorage,
} from "./persistanceLocal";

import qualiSessionsGapsReducer from "./reducers/qualiSessionsGapsSlice";
import qualiSeasonsGapsReducer from "./reducers/qualiSeasonsGapsSlice";
import qualiTeamsOverSeasonsGapsReducer from "./reducers/qualiTeamsOverSeasonGapsSlice";
import qualiTeamsOverMultipleSeasonsGapsReducer from "./reducers/qualiTeamsOverMultipleSeasonsGapsSlice";

// import { autoSaveCloud } from "./reducers/projects/projectsMiddleware";

export const store = configureStore({
  reducer: combineReducers({
    qualiSessionsGaps: qualiSessionsGapsReducer,
    qualiSeasonsGaps: qualiSeasonsGapsReducer,
    qualiTeamsOverSeasonGaps: qualiTeamsOverSeasonsGapsReducer,
    qualiTeamsOverMultipleSeasonsGaps: qualiTeamsOverMultipleSeasonsGapsReducer,
  }),
  // middleware: [autoSaveCloud],
  preloadedState: loadState(),
});

store.subscribe(() => {
  const state = store.getState();

  saveStateInLocalStorage(state);
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
