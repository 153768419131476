import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../store";

import { LATEST_YEAR } from "../../components/util/Constants";

import { SingleSeason } from "../../types/API.types";

interface QualiTeamsOverMultipleSeasonsGapsEntry {
  seasonFrom: number;
  seasonTo: number;
  gaps: SingleSeason[];
}

export interface QualiTeamsOverMultipleSeasonsGapsState {
  entries: QualiTeamsOverMultipleSeasonsGapsEntry[];
  lastSeasonFromInput: number;
  lastSeasonToInput: number;
}

export const initialState: QualiTeamsOverMultipleSeasonsGapsState = {
  entries: [],
  lastSeasonFromInput: LATEST_YEAR - 1,
  lastSeasonToInput: LATEST_YEAR,
};

export const qualiTeamsOverMultipleSeasonsGapsSlice = createSlice({
  name: "qualiTeamsOverMultipleSeasons",
  initialState,
  reducers: {
    addEntry: (
      state: QualiTeamsOverMultipleSeasonsGapsState,
      action: {
        payload: {
          seasonFrom: number;
          seasonTo: number;
          gaps: SingleSeason[];
        };
      }
    ) => {
      const { seasonFrom, seasonTo, gaps } = action.payload;

      state.entries.push({ seasonFrom, seasonTo, gaps });
    },
    setLastSeasonFromInput: (
      state: QualiTeamsOverMultipleSeasonsGapsState,
      action: { payload: { lastSeasonFrom: number } }
    ) => {
      state.lastSeasonFromInput = action.payload.lastSeasonFrom;
    },
    setLastSeasonToInput: (
      state: QualiTeamsOverMultipleSeasonsGapsState,
      action: { payload: { lastSeasonTo: number } }
    ) => {
      state.lastSeasonToInput = action.payload.lastSeasonTo;
    },
  },
});

const { addEntry, setLastSeasonFromInput, setLastSeasonToInput } =
  qualiTeamsOverMultipleSeasonsGapsSlice.actions;

export const QualiTeamsOverMultipleSeasonsGapsAPI = {
  addEntry,
  setLastSeasonFromInput,
  setLastSeasonToInput,
};

export const selectQualiTeamsOverMultipleSeasons = (state: RootState) =>
  state.qualiTeamsOverMultipleSeasonsGaps;

export const qualiTeamsOverMultipleSeasonsGapsEntries = (state: RootState) =>
  selectQualiTeamsOverMultipleSeasons(state).entries;

export const qualiTeamsOverMultipleSeasonsGapsLastSeasonToInput = (
  state: RootState
) => selectQualiTeamsOverMultipleSeasons(state).lastSeasonToInput;
export const qualiTeamsOverMultipleSeasonsGapsLastSeasonFromInput = (
  state: RootState
) => selectQualiTeamsOverMultipleSeasons(state).lastSeasonFromInput;

export default qualiTeamsOverMultipleSeasonsGapsSlice.reducer;
