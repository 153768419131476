import { Outlet } from "react-router-dom";

import { Box } from "@mui/material";

// import TopNavigationBar from "../topNavigationBar/TopNavigationBar";

import "./AppContent.css";

export function AppContent() {
  // const renderNavigationBar = () => <TopNavigationBar></TopNavigationBar>;

  const renderContent = () => <Outlet />;

  return (
    <Box
      sx={{
        flex: 1,
        maxWidth: "1200px",
      }}
    >
      {/* {renderNavigationBar()} */}
      <Box>{renderContent()}</Box>
    </Box>
  );
}
