import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SquareIcon from "@mui/icons-material/Square";
import { Typography } from "@mui/material";

import { GapVisualized } from "./GapVisualized";

import { QualiGapDataType } from "../util/QualiGapDataEnum";
import { getTeamColor } from "../util/team-metadata";

import { ModalQualiGaps } from "../../types/Core.types";
import {
  calculateProgressBarValue,
  getLowestAndHighestValues,
} from "../../utility/Functions";

export default function ModalQualiGapTable(props: {
  gaps: ModalQualiGaps;
  mode: QualiGapDataType;
}) {
  const {
    gaps: { averageGaps, bestGaps },
    mode,
  } = props;

  const isAverage = mode === QualiGapDataType.AVERAGE;

  const gaps = isAverage ? averageGaps : bestGaps;
  const { lowest, highest } = getLowestAndHighestValues(gaps);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Team</TableCell>
            <TableCell align="right">Time</TableCell>
            <TableCell align="center">Gap</TableCell>
            <TableCell align="center">Visualized</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {gaps.map(({ teamName, time, gap }) => (
            <TableRow
              key={teamName}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <SquareIcon sx={{ color: getTeamColor(teamName) }} />
              </TableCell>
              <TableCell component="th" scope="row">
                <Typography>{teamName}</Typography>
              </TableCell>
              <TableCell align="right">
                {time ? `+${(time / 1000).toFixed(3)} s` : "-"}
              </TableCell>
              <TableCell align="center">{gap ? `+${gap} %` : "-"}</TableCell>
              <TableCell>
                <GapVisualized
                  color={getTeamColor(teamName)}
                  value={calculateProgressBarValue(gap, lowest, highest)}
                ></GapVisualized>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
