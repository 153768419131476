import { useState } from "react";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

interface SeasonInputProps {
  onChangeCallback: (newValue: string) => void;
  disabled?: boolean;
}

export const QualiModeRadio = (props: SeasonInputProps) => {
  const { onChangeCallback, disabled } = props;

  const [mode, setMode] = useState("average");

  const handleRadioChange = (event: any) => {
    const newMode = event.target.value;

    setMode(newMode);
    onChangeCallback(newMode);
  };

  return (
    <FormControl disabled={disabled}>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={mode}
        onChange={handleRadioChange}
        row
      >
        <FormControlLabel value="average" control={<Radio />} label="Average" />
        <FormControlLabel value="best" control={<Radio />} label="Best" />
      </RadioGroup>
    </FormControl>
  );
};
