export const teamColors: any = {
  mercedes: '#00d2be',
  redbull: '#0600ef',
  ferrari: '#dc0000',
  mclaren: '#ff8700',
  alphatauri: '#2b4562',
  alpinef1team: '#0090ff',
  alfaromeo: '#900000',
  astonmartin: '#006f62',
  williams: '#005aff',
  haasf1team: '#711c20',
  forceindia: '#ff80c7',
  sauber: '#006eff',
  tororosso: '#469bff',
  renault: '#fff500',
  racingpoint: '#f596c8',
  marussia: '#6e0000',
  lotusf1: '#ffb800',
  jaguar: '#004225',
  jordan: '#ffff00',
  minardi: 'black',
  manormarussia: '#ed1b24',
  brawn: '#b8fd6e',
  arrows: '#ff8000',
  bar: '#eb3434',
  toyota: '#808080',
  honda: '#FFFFFF',
  spyker: '#ff8000', // orange gray
  mf1: '',
  bmwsauber: '',
  spykermf1: '',
  superaguri: '',
  prost: '',
  benetton: '',
  stewart: '',
  tyrrell: '',
  lola: '',
  ligier: '',
  forti: '',
  pacific: '',
  footwork: '',
  simtek: '',
  brabham: '',
  fondmetal: '',
  march: '',
  dallara: '',
  teamlotus: '',
  larrousse: '',
  andreamoda: '',
  ags: '',
  lambo: '',
  coloni: '',
  eurobrun: '',
  leytonhouse: '',
  osella: '',
  onyx: '',
  life: '',
  zakspeed: '',
  ram: '',
  rial: '',
  spirit: '',
  toleman: '',
  fittipaldi: '',
  theodore: '',
  ats: '',
  ensign: '',
  merzario: '',
  rebaque: '',
  shadow: '',
  wolf: '',
  surtees: '',
  kauhsen: '',
  hesketh: '',
  martini: '',
  brm: '',
  penske: '',
  mcguire: '',
  lec: '',
  apollon: '',
  boro: '',
  kojima: '',
  parnelli: '',
  maki: '',
  lyncar: '',
  embassyhill: '',
  token: '',
  tecno: '',
  isomarlboro: '',
  trojan: '',
  amon: '',
  matra: '',
  politoys: '',
  connew: '',
  bellasi: '',
  detomaso: '',
  cooper: '',
  eagle: '',
  protos: '',
  lds: '',
  scirocco: '',
  shannon: '',
  re: '',
  brp: '',
  derrington: '',
  porsche: '',
  gilby: '',
  stebro: '',
  emeryson: '',
  enb: '',
  jbw: '',
  mbm: '',
  behraporsche: '',
  ferguson: '',
  maserati: '',
  watson: '',
  epperly: '',
  scarab: '',
  phillips: '',
  lesovsky: '',
  trevis: '',
  meskowski: '',
  kurtiskraft: '',
  kuzma: '',
  ewing: '',
  Vanwall: '',
  christensen: '',
  moore: '',
  dunn: '',
  sutton: '',
  elder: '',
  fry: '',
  tecmec: '',
  connaught: '',
  osca: '',
  alta: '',
  gordini: '',
  stevens: '',
  bugatti: '',
  hwm: '',
  lancia: '',
  schroeder: '',
  pawl: '',
  pankratz: '',
  arzanivolpini: '',
  klenk: '',
  bromme: '',
  turner: '',
  simca: '',
  nichels: '',
  delroy: '',
  veritas: '',
  emw: '',
  bmw: '',
  frazernash: '',
  sherman: '',
  afm: '',
  deidt: '',
  era: '',
  astonbutterworth: '',
  hall: '',
  cisitalia: '',
  marchese: '',
  langley: '',
  rae: '',
  talbotlago: '',
  wetteroth: '',
  snowberger: '',
  adams: '',
  olson: '',
  milano: '',
  hrt: '',
  coopermaserati: '',
  virgin: '',
  cooperosca: '',
  cooperclimax: '',
  cooperborgward: '',
  coopercastellotti: '',
  lotusclimax: '',
  lotusmaserati: '',
  detomasoalfaromeo: '',
  lotusbrm: '',
  lotusborgward: '',
  cooperalfaromeo: '',
  detomasoferrari: '',
  lotusford: '',
  brabhambrm: '',
  brabhamford: '',
  ldsclimax: '',
  brabhamclimax: '',
  detomasoosca: '',
  mclarenford: '',
  ldsalfaromeo: '',
  cooperford: '',
  mclarenserenissima: '',
  eagleclimax: '',
  brabhamrepco: '',
  eagleweslake: '',
  cooperferrari: '',
  cooperats: '',
  mclarenbrm: '',
  mclarenalfaromeo: '',
  brmford: '',
  matraford: '',
  cooperbrm: '',
  marchford: '',
  marchalfaromeo: '',
  lotusprattwhitney: '',
  shadowford: '',
  shadowmatra: '',
  brabhamalfaromeo: '',
  caterham: '',
  lotus: ''
};
