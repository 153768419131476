import { initialState as qualiSessionsGapsState } from "./reducers/qualiSessionsGapsSlice";
import { initialState as qualiSeasonsGapsState } from "./reducers/qualiSeasonsGapsSlice";
import { initialState as qualiTeamsOverSeasonGapsState } from "./reducers/qualiTeamsOverSeasonGapsSlice";
import { initialState as qualiTeamsOverMultipleSeasonsGapsState } from "./reducers/qualiTeamsOverMultipleSeasonsGapsSlice";

export const initialRootState = {
  qualiSeasonsGapsState,
  qualiSessionsGapsState,
  qualiTeamsOverSeasonGapsState,
  qualiTeamsOverMultipleSeasonsGapsState,
};
