import { ToastContainer, ToastPosition } from "react-toastify";

interface F1GapsToastProps {
  autoClose: number;
  position: ToastPosition;
}

export const F1GapsToast = () => {
  const props: F1GapsToastProps = { autoClose: 1666, position: "bottom-left" };

  return <ToastContainer {...props} />;
};
