import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import Typography from "@mui/material/Typography";
import { Box, TextField } from "@mui/material";

import { SeasonGapsAPI } from "../../../api/gaps/quali/seasonGaps.api";

import {
  QualiGapsMultiSeasonChart,
  QualiGapsMultiSeasonChartInput,
} from "../../charts/QualiGapsMultiSeasonChart";

import {
  ChartTeamSelection,
  TeamCheckedEntry,
} from "../../util/ChartTeamSelection";
import { MultipleSeasonGapsComparisonShortcuts } from "../../util/MultipleSeasonGapsComparisonShortcuts";
import withLoading, { LoadingHOCConsumerProps } from "../../util/LoadingHOC";
import { LATEST_YEAR } from "../../util/Constants";
import { F1GapsToast } from "../../util/F1GapsToast";
import { F1GapsCircularProgress } from "../../util/F1GapsCircularProgress";
import { AccentButton } from "../../../utility/buttons/AccentButton";

import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import {
  QualiTeamsOverMultipleSeasonsGapsAPI,
  qualiTeamsOverMultipleSeasonsGapsEntries,
} from "../../../redux/reducers/qualiTeamsOverMultipleSeasonsGapsSlice";
import {
  qualiTeamsOverMultipleSeasonsGapsLastSeasonFromInput,
  qualiTeamsOverMultipleSeasonsGapsLastSeasonToInput,
} from "../../../redux/reducers/qualiTeamsOverMultipleSeasonsGapsSlice";

import { SingleSeason } from "../../../types/API.types";

interface TeamQualiGapsOverMultipleSeasonsProps
  extends LoadingHOCConsumerProps {}

const TeamQualiGapsOverMultipleSeasons = (
  props: TeamQualiGapsOverMultipleSeasonsProps
) => {
  const { loading, loadingOn, loadingOff } = props;

  const lastSeasonFromInput = useAppSelector(
    qualiTeamsOverMultipleSeasonsGapsLastSeasonFromInput
  );
  const lastSeasonToInput = useAppSelector(
    qualiTeamsOverMultipleSeasonsGapsLastSeasonToInput
  );

  const [seasonFrom, setSeasonFrom] = useState(
    lastSeasonFromInput || LATEST_YEAR - 1
  );
  const [seasonTo, setSeasonTo] = useState(lastSeasonToInput || LATEST_YEAR);
  const [gaps, setGaps] = useState<QualiGapsMultiSeasonChartInput[] | null>(
    null
  );
  const [teams, setTeams] = useState<string[]>([]);
  const [checkedTeams, setCheckedTeams] = useState<TeamCheckedEntry[]>([]);

  const appDispatch = useAppDispatch();

  const teamQualiGapsOverMultipleSeasonEntries = useAppSelector(
    qualiTeamsOverMultipleSeasonsGapsEntries
  );

  const updateSeason = (newValue: number, cb: (s: number) => void) => {
    cb(newValue);
  };

  const updateSeasonTo = (newSeasonTo: number) => {
    updateSeason(newSeasonTo, setSeasonTo);

    appDispatch(
      QualiTeamsOverMultipleSeasonsGapsAPI.setLastSeasonToInput({
        lastSeasonTo: newSeasonTo,
      })
    );
  };

  const updateSeasonFrom = (newSeasonFrom: number) => {
    updateSeason(newSeasonFrom, setSeasonFrom);

    appDispatch(
      QualiTeamsOverMultipleSeasonsGapsAPI.setLastSeasonFromInput({
        lastSeasonFrom: newSeasonFrom,
      })
    );
  };

  const submit = () => {
    getTeamQualiGapsOverMultipleSeasons(seasonFrom, seasonTo);
  };

  const getTeamQualiGapsOverMultipleSeasons = (
    seasonFromArg: number,
    seasonToArg: number
  ) => {
    loadingOn();

    const entry = teamQualiGapsOverMultipleSeasonEntries.find(
      ({ seasonFrom, seasonTo }) =>
        seasonFrom === seasonFromArg && seasonTo === seasonToArg
    );

    if (!entry) {
      executeRequest(seasonFromArg, seasonToArg);
    } else {
      const formattedData = formatData(entry.gaps);

      setGaps(formattedData);
      loadingOff();

      if (seasonToArg === LATEST_YEAR || seasonFromArg === LATEST_YEAR) {
        executeRequest(seasonFromArg, seasonToArg);
      }
    }
  };

  const executeRequest = (seasonFromArg: number, seasonToArg: number) => {
    SeasonGapsAPI.getTeamGapsOverMultipleSeasons(seasonFromArg, seasonToArg, {
      onSuccess: (response: SingleSeason[]) => {
        const formattedData = formatData(response);

        setGaps(formattedData);

        appDispatch(
          QualiTeamsOverMultipleSeasonsGapsAPI.addEntry({
            seasonFrom: seasonFromArg,
            seasonTo: seasonToArg,
            gaps: response,
          })
        );
      },
      onFailed: (err) => toast.error(err),
      onDone: () => loadingOff(),
    });
  };

  function formatData(
    seasonsGaps: SingleSeason[]
  ): QualiGapsMultiSeasonChartInput[] {
    const result: QualiGapsMultiSeasonChartInput[] = [];

    const teams: string[] = [];

    seasonsGaps.forEach((seasonGaps) => {
      seasonGaps.gaps.forEach(({ teamName }) => {
        if (!teams.includes(teamName)) teams.push(teamName);
      });
    });

    teams.forEach((teamName) => {
      seasonsGaps.forEach(({ season, gaps }) => {
        const gapIdx = gaps.findIndex((e) => e.teamName === teamName);

        if (gapIdx < 0) return;

        const resultTeamIdx = result.findIndex((e) => e.teamName === teamName);

        if (resultTeamIdx < 0) {
          result.push({ teamName, gaps: [{ season, gap: gaps[gapIdx].gap }] });
        } else {
          const oldEntry = result[resultTeamIdx];
          oldEntry.gaps.push({ season, gap: gaps[gapIdx].gap });
        }
      });
    });

    setTeams(teams);
    setCheckedTeams(teams.map((e) => ({ teamName: e, checked: true })));

    return result;
  }

  useEffect(
    () => getTeamQualiGapsOverMultipleSeasons(seasonFrom, seasonTo),
    []
  );

  return (
    <Box>
      <Typography>
        Use this tab to see the average quali gaps for a team over all races of
        a season.
      </Typography>

      <Box sx={{ marginTop: "10px" }}>
        <Box
          sx={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            justifyItems: "center",
          }}
        >
          <TextField
            sx={{ marginRight: "10px" }}
            value={seasonFrom}
            label="From"
            onChange={(event: any) => updateSeasonFrom(event.target.value)}
            disabled={loading}
          />

          <TextField
            sx={{ marginLeft: "10px" }}
            value={seasonTo}
            label="To"
            onChange={(event: any) => updateSeasonTo(event.target.value)}
            disabled={loading}
          />

          <AccentButton
            sx={{ marginLeft: "10px", marginTop: "10px" }}
            onClick={submit}
            disabled={loading}
          >
            Get
            {loading ? <F1GapsCircularProgress /> : null}
          </AccentButton>
        </Box>

        <Box sx={{ paddingTop: "10px" }}>
          <MultipleSeasonGapsComparisonShortcuts
            getDataCall={getTeamQualiGapsOverMultipleSeasons}
            disabled={loading}
            setSeasonFrom={updateSeasonFrom}
            setSeasonTo={updateSeasonTo}
          />
        </Box>
      </Box>

      {gaps ? (
        <Box>
          <ChartTeamSelection
            teams={teams}
            onChangeCallback={setCheckedTeams}
            disabled={loading}
          />
          <QualiGapsMultiSeasonChart checkedTeams={checkedTeams} data={gaps} />
        </Box>
      ) : null}

      <F1GapsToast />
    </Box>
  );
};

export default withLoading(TeamQualiGapsOverMultipleSeasons);
