import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

import { SeasonGapsAPI } from "../../../api/gaps/quali/seasonGaps.api";

import { QualiGapsLineChart } from "../../charts/QualiGapsLineChart";

import { SeasonInput } from "../../util/SeasonInput";
import { QualiModeRadio } from "../../util/QualiModeRadio";
import { LATEST_YEAR, FIRST_YEAR } from "../../util/Constants";
import { F1GapsToast } from "../../util/F1GapsToast";
import {
  ChartTeamSelection,
  TeamCheckedEntry,
} from "../../util/ChartTeamSelection";
import withLoading, { LoadingHOCConsumerProps } from "../../util/LoadingHOC";
import { F1GapsCircularProgress } from "../../util/F1GapsCircularProgress";

import { TeamQualiGapsOverSeasonAPIResponse } from "../../../types/API.types";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  QualiTeamsOverSeasonGapsAPI,
  qualiTeamsOverSeasonGapsEntries,
  qualiTeamsOverSeasonGapsLastSeasonInput,
} from "../../../redux/reducers/qualiTeamsOverSeasonGapsSlice";

interface TeamQualiGapsOverSeasonProps extends LoadingHOCConsumerProps {}

const TeamQualiGapsOverSeason = (props: TeamQualiGapsOverSeasonProps) => {
  const { loading, loadingOn, loadingOff } = props;

  const lastSeasonEntry = useAppSelector(
    qualiTeamsOverSeasonGapsLastSeasonInput
  );

  const [season, setSeason] = useState(lastSeasonEntry || LATEST_YEAR);
  const [mode, setMode] = useState("average");
  const [gaps, setGaps] = useState<TeamQualiGapsOverSeasonAPIResponse | null>(
    null
  );
  const [checkedTeams, setCheckedTeams] = useState<TeamCheckedEntry[]>([]);

  const appDispatch = useAppDispatch();

  const teamQualiGapsOverSeasonEntries = useAppSelector(
    qualiTeamsOverSeasonGapsEntries
  );

  const updateSeason = (newSeason: number) => {
    setSeason(newSeason);

    if (LATEST_YEAR >= newSeason && FIRST_YEAR <= newSeason) {
      getTeamQualiGapsOverSeason(newSeason);

      appDispatch(
        QualiTeamsOverSeasonGapsAPI.setLastSeasonInput({
          lastSeason: newSeason,
        })
      );
    }
  };

  const updateGaps = (newGaps: TeamQualiGapsOverSeasonAPIResponse) => {
    setGaps(newGaps);
    setCheckedTeams(newGaps.teams.map((e) => ({ teamName: e, checked: true })));
  };

  const getTeamQualiGapsOverSeason = (seasonToLookUp: number) => {
    loadingOn();

    const entry = teamQualiGapsOverSeasonEntries.find(
      (e) => e.season === seasonToLookUp
    );

    if (!entry) {
      executeRequest(seasonToLookUp);
    } else {
      updateGaps(entry.gaps);
      loadingOff();

      if (seasonToLookUp === LATEST_YEAR) {
        executeRequest(seasonToLookUp);
      }
    }
  };

  const executeRequest = (seasonToLookUp: number) => {
    SeasonGapsAPI.getTeamGapsOverSeason(seasonToLookUp, {
      onSuccess: (response: TeamQualiGapsOverSeasonAPIResponse) => {
        updateGaps(response);

        appDispatch(
          QualiTeamsOverSeasonGapsAPI.addEntry({
            season: seasonToLookUp,
            gaps: response,
          })
        );
      },
      onFailed: (err) => toast.error(err),
      onDone: () => loadingOff(),
    });
  };

  useEffect(() => {
    getTeamQualiGapsOverSeason(season);
  }, []);

  return (
    <Box>
      <Typography>
        Use this tab to see the average quali gaps for a team over all races of
        a season.
      </Typography>

      <Box>
        <Box
          sx={{
            paddingTop: "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SeasonInput
            onChangeCallback={updateSeason}
            disabled={loading}
            initialValue={season}
          />
          <QualiModeRadio onChangeCallback={setMode} disabled={loading} />
          {loading ? <F1GapsCircularProgress /> : null}

          {gaps ? (
            <ChartTeamSelection
              teams={gaps.teams}
              onChangeCallback={setCheckedTeams}
              disabled={loading}
            />
          ) : null}
        </Box>

        {gaps ? (
          <QualiGapsLineChart
            gapData={{
              teams: gaps.teams.filter(
                (team) => checkedTeams.find((e) => e.teamName === team)?.checked
              ),
              payload:
                mode === "average" ? gaps.teamsAverageGaps : gaps.teamsBestGaps,
            }}
          />
        ) : null}

        <F1GapsToast />
      </Box>
    </Box>
  );
};

export default withLoading(TeamQualiGapsOverSeason);
