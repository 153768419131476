import LinearProgress from "@mui/material/LinearProgress";

interface GapVisualizedProps {
  color: string;
  value: number;
}

export const GapVisualized = (props: GapVisualizedProps) => {
  const { color, value } = props;

  return (
    <LinearProgress
      sx={{ color }}
      color={"inherit"}
      variant="determinate"
      value={value}
    ></LinearProgress>
  );
};
