import { createContext, useContext, useState } from "react";

export interface LoadingHOCConsumerProps {
  loading: boolean;
  loadingOn: () => void;
  loadingOff: () => void;
}

const withLoading = (WrappedComponent: any) => (props: any) => {
  const [loading, setLoading] = useState(false);

  const loadingOn = () => setLoading(true);
  const loadingOff = () => setLoading(false);

  const ctx = useContext(createContext({ loading, loadingOn, loadingOff }));

  return (
    <WrappedComponent
      {...props}
      loading={ctx.loading}
      loadingOn={ctx.loadingOn}
      loadingOff={ctx.loadingOff}
    />
  );
};

export default withLoading;
