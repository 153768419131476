import { initialRootState } from "./state";
import { RootState } from "./store";

const KEY = "redux";

export function loadState() {
  try {
    const serializedState = localStorage.getItem(KEY);
    if (!serializedState) return initialRootState;

    return JSON.parse(serializedState);
  } catch (error) {
    return initialRootState;
  }
}

export async function saveState(state: RootState) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(KEY, serializedState);
  } catch (e) {
    console.error(`Error ${JSON.stringify(e)} while saving state.`);
  }
}
