import { CircularProgress } from "@mui/material";

interface F1GapsCircularProgressProps {
  size: number;
}

export const F1GapsCircularProgress = () => {
  const props: F1GapsCircularProgressProps = {
    size: 20,
  };

  return <CircularProgress {...props} />;
};
