export const themeOptions = {
  palette: {
    type: "light",
    primary: {
      main: "#7e8e7e",
      contrastText: "#ffffff",
      light: "#778577",
      dark: "#5c645c",
    },
    secondary: {
      main: "rgba(94,96,94,0.77)",
      contrastText: "#292b28",
    },
    background: {
      default: "#CDD0CB",
      paper: "#E8EAE6",
    },
    info: {
      main: "#940128",
    },
    warning: {
      main: "#507750",
    },
    error: {
      main: "#b51408",
    },
    accent: {
      main: "green",
    },
  },
  typography: {
    fontFamily: "custom-font",
  },
  shape: {
    borderRadius: 15,
  },
  props: {
    MuiAppBar: {
      color: "transparent",
    },
  },
};

export const AccentColor = themeOptions.palette.accent.main;
