import { Box } from "@mui/material";

import GapsTabs from "../components/GapsTabs";

export const GapsPage = () => {
  return (
    <Box>
      <GapsTabs />
    </Box>
  );
};
