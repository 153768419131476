import axios from "axios";

import { BACKEND_BASE_URL } from "./config";

const getUrl = (route: string) => `${BACKEND_BASE_URL}/${route}`;

export const buildHeaders = () => {
  return {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    // "Access-Control-Allow-Origin": "*",
  };
};

export const postRequest = async (
  route: string,
  data: any,
  successCallback?: (data: any) => void,
  failedCallback?: (err: string) => void
) => {
  const response = axios.post(getUrl(route), data, {
    headers: buildHeaders(),
  });

  if (successCallback) {
    return response.then(successCallback).catch((err) => {
      if (err.response) {
        // request made and server responded
        if (failedCallback) failedCallback(err.response);
      } else if (err.request) {
        // request made, no response
        if (failedCallback) failedCallback(err.response);
      } else {
        // something else
        console.error("[api/post/undefinedError]", err);
      }
    });
  } else {
    return response;
  }
};

export const getRequest = (
  route: string,
  params: any = null,
  successCallback: (data: any) => void,
  failedCallback: (err: string) => void
) => {
  axios
    .get(getUrl(route), {
      headers: buildHeaders(),
      params,
    })
    .then(successCallback)
    .catch((err) => {
      if (err.response) {
        // request made and server responded
        if (failedCallback) failedCallback(err.response);
      } else if (err.request) {
        // request made, no response
        if (failedCallback) failedCallback(err.response);
      } else {
        // something else
      }
    });
};
