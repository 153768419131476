import { StrictMode } from "react";
import ReactDOM from "react-dom";

import { ThemeProvider } from "@emotion/react";

import App from "./App";

import { appTheme } from "./Theme";

import reportWebVitals from "./reportWebVitals";

import "./index.css";

ReactDOM.render(
  <StrictMode>
    <ThemeProvider theme={appTheme}>
      <App />
    </ThemeProvider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
