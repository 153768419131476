import { QualiGapEntry } from "../components/util/QualiGapTable";
import { ModalQualiGaps } from "../types/Core.types";

export function calculateProgressBarValue(
  gap: number,
  lowest: number,
  highest: number
) {
  return (100 * gap) / (highest - lowest);
}

export function getLowestAndHighestValues(gaps: QualiGapEntry[]) {
  const gapValues = gaps.filter((e) => !isNaN(e.gap)).map((e) => e.gap);

  const lowest = Math.floor(Math.min(...gapValues));
  const highest = Math.ceil(Math.max(...gapValues));

  return { lowest, highest };
}
