import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../store";

import { QualiGapEntry } from "../../components/util/QualiGapTable";
import { LATEST_YEAR } from "../../components/util/Constants";

interface QualiSeasonsGapsEntry {
  season: number;
  gaps: QualiGapEntry[];
}

export interface QualiSeasonsGapsState {
  entries: QualiSeasonsGapsEntry[];
  lastSeasonInput: number;
}

export const initialState: QualiSeasonsGapsState = {
  entries: [],
  lastSeasonInput: LATEST_YEAR,
};

export const qualiSeasonsGapsSlice = createSlice({
  name: "qualiSeasons",
  initialState,
  reducers: {
    addEntry: (
      state: QualiSeasonsGapsState,
      action: {
        payload: {
          season: number;
          gaps: QualiGapEntry[];
        };
      }
    ) => {
      const { season, gaps } = action.payload;

      state.entries.push({ season, gaps });
    },
    setLastSeasonInput: (
      state: QualiSeasonsGapsState,
      action: { payload: { lastSeason: number } }
    ) => {
      state.lastSeasonInput = action.payload.lastSeason;
    },
  },
});

const { addEntry, setLastSeasonInput } = qualiSeasonsGapsSlice.actions;

export const QualiSeasonsReduxAPI = {
  addEntry,
  setLastSeasonInput,
};

export const selectQualiSeasons = (state: RootState) => state.qualiSeasonsGaps;

export const qualiSeasonsGapsLastSeason = (state: RootState) =>
  selectQualiSeasons(state).lastSeasonInput;

export const qualiSeasonsGapsEntries = (state: RootState) =>
  selectQualiSeasons(state).entries;

export default qualiSeasonsGapsSlice.reducer;
