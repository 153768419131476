import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";

import { store } from "./redux/store";

import { GapsPage } from "./pages/Gaps.page";

import { AppContent } from "./components/appContent/AppContent";

import "../public/favicon.ico";
import "./App.css";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <div className="App">
                <AppContent></AppContent>
              </div>
            }
          >
            <Route index element={<GapsPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
