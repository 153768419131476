import * as React from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import SeasonQualiGaps from "./tabs/season/SeasonQualiGaps";
import SessionQualiGaps from "./tabs/session/SessionQualiGaps";
import TeamQualiGapsOverSeason from "./tabs/season/TeamQualiGapsOverSeason";
import TeamQualiGapsOverMultipleSeasons from "./tabs/season/TeamQualiGapsOverMultipleSeasons";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function GapsTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ dsplay: "flex", width: "100%", alignSelf: "center" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Session" {...a11yProps(0)} />
          <Tab label="Season" {...a11yProps(1)} />
          <Tab label="Season evolution" {...a11yProps(2)} />
          <Tab label="Multiple seasons evolution" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <SessionQualiGaps />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SeasonQualiGaps />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TeamQualiGapsOverSeason />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TeamQualiGapsOverMultipleSeasons />
      </TabPanel>
    </Box>
  );
}
