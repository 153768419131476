import { Callbacks } from "../../types/util";
import { getRequest } from "../index";

const getGeneralSeasonsUrl = (route: string | number) => `seasons/${route}`;

async function getSeasonMetadata(
  season: number,
  { onSuccess, onFailed, onDone }: Callbacks
) {
  getRequest(
    getGeneralSeasonsUrl(season),
    { season },
    (response: any) => {
      try {
        if (response.data && response.data.errorMessage) {
          const { errorMessage } = response.data;

          console.error(
            `[api/gaps/quali/seasonGaps] Error while loading season quali gaps: ${errorMessage}`
          );

          if (onFailed) onFailed(errorMessage);

          return { error: errorMessage };
        }

        const rounds = response.data;

        if (onSuccess) onSuccess(rounds);
      } catch (error) {
        console.error(
          `[api/gaps/quali/seasonGaps] Error while loading season quali gaps: ${error}`
        );

        if (onFailed) onFailed(error);
      } finally {
        if (onDone) onDone();
      }
    },
    (error: any) => console.log("error on get season quali gaps", error)
  );
}

export const SeasonGeneralAPI = {
  getSeasonMetadata,
};
