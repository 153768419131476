import { useState } from "react";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { SessionRound } from "../../types/Core.types";

interface RoundInputProps {
  rounds: SessionRound[];
  onChangeCallback: (newValue: number) => void;
  initialValue: number;
  disabled?: boolean;
}

export const RoundInput = (props: RoundInputProps) => {
  const { rounds, onChangeCallback, initialValue, disabled } = props;

  const [round, setRound] = useState<string | null>(null);

  const onChangeLocal = (event: any) => {
    const {
      target: { value },
    } = event;

    setRound(value);
    onChangeCallback(value);
  };

  const emptyRounds = <MenuItem>Loading...</MenuItem>;
  const roundsToDisplay = rounds.map(({ round, eventName }: SessionRound) => (
    <MenuItem value={round} key={round}>
      {eventName}
    </MenuItem>
  ));

  return (
    <Box sx={{ minWidth: 200 }}>
      <FormControl disabled={disabled}>
        <InputLabel id="demo-simple-select-label">Round</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={round || initialValue}
          label="Round"
          onChange={onChangeLocal}
        >
          {!rounds.length ? emptyRounds : roundsToDisplay}
        </Select>
      </FormControl>
    </Box>
  );
};
