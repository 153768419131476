import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getTeamColor } from "../util/team-metadata";
import { QualiGapEntry } from "../util/QualiGapTable";

interface ChartDataRawInput {
  teamName: string;
  gaps: {
    gapData: QualiGapEntry[];
    raceWeekendData: { round: number; eventName: string };
  }[];
}

interface QualiGapsLineChartInput {
  gapData: { teams: string[]; payload: ChartDataRawInput[] };
}

export const QualiGapsLineChart = (props: QualiGapsLineChartInput) => {
  const {
    gapData: { teams, payload },
  } = props;

  const series: {
    teamName: string;
    data: { eventName: string; gap: any }[];
  }[] = reformatReceivedData(payload);

  const dataLines = series
    .filter((s) => teams.includes(s.teamName))
    .map(({ data, teamName }) => {
      return (
        <Line
          dataKey="gap"
          data={data}
          name={teamName}
          stroke={getTeamColor(teamName)}
          key={teamName}
        />
      );
    });

  return (
    <ResponsiveContainer width="100%" height={600}>
      <LineChart margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
        <XAxis
          dataKey="eventName"
          type="category"
          allowDuplicatedCategory={false}
          interval={0}
          angle={15}
        />
        <YAxis dataKey="gap" tickFormatter={(e) => (e ? `${e} s.` : "")} />
        <Tooltip
          formatter={(e: any) => `+${e} s.`}
          itemSorter={(item) => item.value as number}
        />
        <CartesianGrid stroke="#f5f5f5" />
        {dataLines}
      </LineChart>
    </ResponsiveContainer>
  );
};

function reformatReceivedData(payload: ChartDataRawInput[]): any[] {
  const result: any[] = [];

  payload.forEach(({ gaps }: ChartDataRawInput) => {
    gaps.forEach(({ gapData, raceWeekendData }) => {
      const { teamName, gap } = gapData[0];
      const { eventName } = raceWeekendData;

      const trimmedEventName = eventName.replace("Grand Prix", "GP");

      const resultIndex = result.findIndex((e) => e.teamName === teamName);

      if (resultIndex < 0) {
        const obj: any = {
          teamName,
          data: [{ eventName: trimmedEventName, gap: Number(gap) }],
        };

        result.push(obj);
      } else {
        const updated = { ...result[resultIndex] };
        updated.data.push({ eventName: trimmedEventName, gap: Number(gap) });

        result.splice(resultIndex, 1, updated);
      }
    });
  });

  return result;
}
