import { Box } from "@mui/material";
import { LATEST_YEAR } from "./Constants";
import { AccentButton } from "../../utility/buttons/AccentButton";

interface ShortcutsProps {
  getDataCall: (seasonFrom: number, seasonTo: number) => void;
  disabled?: boolean;
  setSeasonFrom: (seasonFrom: number) => void;
  setSeasonTo: (seasonTo: number) => void;
}

const StyledButton = (props: {
  cb: () => void;
  disabled?: boolean;
  label: string;
}) => {
  const { cb, disabled, label } = props;

  return (
    <AccentButton sx={{ margin: "5px" }} onClick={cb} disabled={disabled}>
      {label}
    </AccentButton>
  );
};

export const MultipleSeasonGapsComparisonShortcuts = (
  props: ShortcutsProps
) => {
  const { getDataCall, disabled, setSeasonFrom, setSeasonTo } = props;

  const invokeShortcut = (seasonFrom: number, seasonTo: number) => {
    setSeasonFrom(seasonFrom);
    setSeasonTo(seasonTo);

    getDataCall(seasonFrom, seasonTo);
  };

  const getLastTwoSeasons = () => invokeShortcut(LATEST_YEAR - 1, LATEST_YEAR);
  const getLastTenSeasons = () => invokeShortcut(LATEST_YEAR - 9, LATEST_YEAR);
  const getV10Era = () => invokeShortcut(1996, 2005);
  const getV8Era = () => invokeShortcut(2006, 2013);
  const getHybridEra = () => invokeShortcut(2014, LATEST_YEAR);

  return (
    <Box>
      <StyledButton
        cb={getLastTwoSeasons}
        label="Last 2 seasons"
        disabled={disabled}
      />
      <StyledButton
        cb={getLastTenSeasons}
        label="Last 10 seasons"
        disabled={disabled}
      />
      <StyledButton cb={getV10Era} label="V10 era" disabled={disabled} />
      <StyledButton cb={getV8Era} label="V8 era" disabled={disabled} />
      <StyledButton cb={getHybridEra} label="Hybrid era" disabled={disabled} />
    </Box>
  );
};
