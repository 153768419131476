import { useState } from "react";

import Checkbox from "@mui/material/Checkbox";
import { Box } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";

import { getTeamColor } from "./team-metadata";

interface SeasonInputProps {
  onChangeCallback: (newValue: TeamCheckedEntry[]) => void;
  teams: string[];
  disabled?: boolean;
}

export interface TeamCheckedEntry {
  teamName: string;
  checked: boolean;
}

export const ChartTeamSelection = (props: SeasonInputProps) => {
  const { teams, onChangeCallback, disabled } = props;

  const [teamsCheckStatus, setTeamsCheckStatus] = useState<TeamCheckedEntry[]>(
    teams.map((team) => ({ teamName: team, checked: true }))
  );

  const updateTeamCheckedStatus = (teamName: string, newChecked: boolean) => {
    const idx = teamsCheckStatus.findIndex((e) => e.teamName === teamName);

    const updated = [...teamsCheckStatus];
    updated.splice(idx, 1, {
      teamName,
      checked: newChecked,
    });

    setTeamsCheckStatus(updated);
    onChangeCallback(updated);
  };

  const children = teams.map((team) => (
    <FormControlLabel
      key={team}
      label={team}
      disabled={disabled}
      control={
        <Checkbox
          sx={{
            color: getTeamColor(team),
            "&.Mui-checked": {
              color: getTeamColor(team),
            },
          }}
          checked={teamsCheckStatus.find((e) => e.teamName === team)?.checked}
          onChange={(event: any) =>
            updateTeamCheckedStatus(team, event.target.checked)
          }
        ></Checkbox>
      }
    />
  ));

  const setCheckedAll = (checked: boolean) => {
    const updatedChecks = teams.map((e) => ({ teamName: e, checked }));
    setTeamsCheckStatus(updatedChecks);

    return updatedChecks;
  };

  const enableAll = () => setCheckedAll(true);
  const disableAll = () => setCheckedAll(false);

  const updateMasterCheckbox = (checked: boolean) => {
    const cb = checked ? enableAll : disableAll;
    const updatedCheckStatuses = cb();

    onChangeCallback(updatedCheckStatuses);
  };

  const areAllChecked = teamsCheckStatus.every((e) => e.checked);
  const areSomeChecked = teamsCheckStatus.some((e) => e.checked);

  return (
    <Box>
      <FormControlLabel
        label="All"
        disabled={disabled}
        control={
          <Checkbox
            checked={areAllChecked}
            indeterminate={areSomeChecked && !areAllChecked}
            onChange={(event: any) =>
              updateMasterCheckbox(event.target.checked)
            }
          />
        }
      />

      {children}
    </Box>
  );
};
