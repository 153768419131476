import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SquareIcon from "@mui/icons-material/Square";
import { getTeamColor } from "./team-metadata";
import { GapVisualized } from "../tables/GapVisualized";
import {
  calculateProgressBarValue,
  getLowestAndHighestValues,
} from "../../utility/Functions";

export interface QualiGapEntry {
  teamName: string;
  time: number;
  gap: number;
}

export default function QualiGapTable(props: { gaps: QualiGapEntry[] }) {
  const { gaps } = props;

  const { lowest, highest } = getLowestAndHighestValues(gaps);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Team</TableCell>
            <TableCell align="right">Time</TableCell>
            <TableCell align="right">Gap</TableCell>
            <TableCell align="right">Visualized</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {gaps.map(({ teamName, time, gap }) => (
            <TableRow
              key={teamName}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <SquareIcon sx={{ color: getTeamColor(teamName) }} />
              </TableCell>
              <TableCell component="th" scope="row">
                {teamName}
              </TableCell>
              <TableCell align="right">
                {(time / 1000).toFixed(3) + " s."}
              </TableCell>
              <TableCell align="right">{gap}</TableCell>
              <TableCell>
                <GapVisualized
                  color={getTeamColor(teamName)}
                  value={calculateProgressBarValue(gap, lowest, highest)}
                ></GapVisualized>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
